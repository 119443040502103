(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/constants/assets/javascripts/enum.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/constants/assets/javascripts/enum.js');
"use strict";

const CompetitionType = {
  None: 'None',
  TipsSM: 'TipsSM',
  Challenge: 'Challenge',
  TipsEM: 'TipsEM'
};
const OwnerType = {
  None: 'None',
  Svs: 'Svs',
  Retailer: 'Retailer',
  Customer: 'Customer'
};
const ParticipantType = {
  All: 'All',
  Customer: 'Customer',
  CustomerAsRetailers: 'CustomerAsRetailers'
};
const SignupType = {
  UNDEFINED: 'UNDEFINED',
  PARTICIPATE: 'PARTICIPATE',
  NOT_PARTICIPATE: 'NOT_PARTICIPATE',
  RESET_CHOICE: 'RESET_CHOICE'
};
const ScopeType = {
  INDIVIDUAL_ONLY: 'INDIVIDUAL_ONLY'
};
const TeamType = {
  None: 'None',
  Defined: 'Defined',
  Retailer: 'Retailer',
  Location: 'Location'
};
const CompetitionState = {
  Undefined: 'Undefined',
  Defined: 'Defined',
  Open: 'Open',
  ResultsPending: 'ResultsPending',
  ResultsFinalised: 'ResultsFinalised',
  CompetitionComplete: 'CompetitionComplete'
};
const SystemType = {
  SRow: 'SRow',
  MSys: 'MSys',
  RSys: 'RSys',
  USys: 'USys',
  URow: 'URow'
};
const FetchState = {
  Idle: 'idle',
  Pending: 'pending',
  Fulfilled: 'fulfilled',
  Rejected: 'rejected'
};
const CacheTime = {
  Found: {
    minutes: 10
  },
  NotFound: {
    minutes: 10
  }
};
const competitionNames = {
  TIPS_SM_NAME: 'Tips-SM',
  TIPS_EM_NAME: 'Europamästaren',
  TIPS_EM_YEAR: '2023',
  TIPS_SM_YEAR: '2025'
};
const competitionUrls = {
  TIPS_SM_URL: 'tips-sm',
  TIPS_EM_URL: 'europamastaren'
};
const enums = {
  CompetitionType,
  OwnerType,
  ParticipantType,
  SignupType,
  ScopeType,
  CompetitionState,
  SystemType,
  TeamType,
  FetchState,
  CacheTime,
  competitionNames,
  competitionUrls
};
if (svs.isServer) {
  module.exports = enums;
} else {
  setGlobal('svs.components.marketplaceCompetition.constants', enums);
}

 })(window);